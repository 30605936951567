
import { defineComponent, ref, onMounted } from "vue";
import axios from "axios";
import VehicleForm from "@/components/vehicles/VehicleForm.vue";
import ImagesVehicle from "@/components/vehicles/ImagesVehicle.vue";
import DescripcionVehicle from "@/components/vehicles/DescripcionVehicle.vue";
import DesperfectosVehicle from "@/components/vehicles/DesperfectosVehicle.vue";
import { useRoute, useRouter } from "vue-router";
import { showModal } from "@/core/helpers/dom";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "NewVehicle",
  components: {
    VehicleForm,
    ImagesVehicle,
    DescripcionVehicle,
    DesperfectosVehicle,
  },
  setup() {
    const step = ref<number>(1);
    const sendForm = ref<boolean>(false);
    const sendGalery = ref<boolean>(false);
    const sendDescripcion = ref<boolean>(false);
    const sendDesperfectos = ref<boolean>(false);
    const router = useRouter();
    const formStepOne = ref<any>([]);
    const galery = ref<any>([]);
    const descripcion = ref<any>([]);
    const desperfectosData = ref<any>([]);
    const uploadVehicleRef = ref();
    const route = useRoute();
    const dataEditVehicle = ref<any>();
    const dataDescription = ref<any>();
    const sendDataDescription = ref<any>({});
    const imgVehicle = ref<any>([]);
    const sendImagesEditVehicle = ref<any>([]);
    const imgDesperfecto = ref<any>([]);
    const sendImgDesperfecto = ref<any>([]);
    const idToEdit = ref<any>();
    const isEdit = ref<boolean>(false);
    const idAuto = ref<any>();
    const showLoader = ref<boolean>(false);

    const regresar = () => {
      if (step.value == 1) {
        router.push("/vehicles");
      }
      if (step.value == 2) {
        step.value = 1;
        sendForm.value = false;
      }
      if (step.value == 3) {
        step.value = 2;
        sendGalery.value = false;
      }
      if (step.value == 4) {
        step.value = 3;
        sendDescripcion.value = false;
      }
    };

    const siguiente = () => {
      if (step.value == 1) {
        sendForm.value = true;
      }
      if (step.value == 2) {
        sendGalery.value = true;
      }
      if (step.value == 3) {
        sendDescripcion.value = true;
      }
      if (step.value == 4) {
        sendDesperfectos.value = true;
      }
    };

    const stepIValidation = (evt) => {
      if (evt.success) {
        formStepOne.value = evt.data;
        console.log("🚀 ~ file: NewVehicle.vue:334 ~ stepIValidation ~ formStepOne.value", formStepOne.value)
        step.value = 2;
        if (idToEdit.value != undefined) {
          sendImagesEditVehicle.value = imgVehicle.value;
        }
      } else {
        sendForm.value = evt.success;
      }
    };

    const stepIIValidation = (evt) => {
      if (evt.success) {
        galery.value = evt.data;
        step.value = 3;
        if (idToEdit.value != undefined) {
          sendDataDescription.value = dataDescription.value;
        }
      } else {
        sendGalery.value = evt.success;
      }
    };

    const stepIIIValidation = (evt) => {
      if (evt.success) {
        descripcion.value = evt.data;
        step.value = 4;
        if (idToEdit.value != undefined) {
          sendImgDesperfecto.value = imgDesperfecto.value;
        }
      } else {
        sendDescripcion.value = evt.success;
      }
    };

    const stepIVValidation = (evt) => {
      if (evt.success) {
        desperfectosData.value = evt.data;
        saveVehicle();
      } else {
        sendDesperfectos.value = evt.success;
      }
    };

    const saveVehicle = async () => {
      const { data } = await axios.get(process.env.VUE_APP_API_URL + "/api/get-current-agency");
      let currencyAgencyId = 1;
      if(data.data.id != undefined && data.data.id != null){
        currencyAgencyId = data.data.id;
      }
      showLoader.value = true;
      const dataVehicle = {
        vin: formStepOne.value.vin,
        brand: formStepOne.value.id_brand,
        brand_name: formStepOne.value.brand_name,
        model: formStepOne.value.id_model,
        model_name: formStepOne.value.model_name,
        version: formStepOne.value.version,
        version_name: formStepOne.value.version_name,
        color: formStepOne.value.color,
        transmission: formStepOne.value.transmision,
        carroceria: formStepOne.value.carroceria,
        fuel: formStepOne.value.combustible,
        mileage: formStepOne.value.kilometraje.replace(/,/g, ""),
        has_certificate: formStepOne.value.certificado,
        price: formStepOne.value.precio.replace(/,/g, ""),
        days_in_floor: formStepOne.value.piso,
        agency:currencyAgencyId,
        features: [1, 2, 3],
        unique_owner: descripcion.value.dueno,
        agencies_services: descripcion.value.servicios,
        status: 2,
        additional_description: descripcion.value.descripcion,
        year: formStepOne.value.year,
        year_name: formStepOne.value.id_year,
      };
      //try {
      if (idToEdit.value != undefined) {
        isEdit.value = true;
        let url =
          process.env.VUE_APP_API_URL + "/api/vehicles/" + idToEdit.value;
        await axios
          .patch(url, dataVehicle)
          .then((response) => {
            if (response.data.success) {
              idAuto.value = idToEdit.value;
              saveImagesGalery(idToEdit.value);
            }
          })
          .catch((error) => {
            if (error.response) {
              let errors = Object.entries(error.response.data.errors);
              let mensaje = "";
              errors.forEach((value, index) => {
                mensaje += value[1];
              });
              Swal.fire({
                position: "center",
                icon: "error",
                title: mensaje,
                showConfirmButton: true,
              });
              sendDesperfectos.value = false;
              showLoader.value = false;
            }
          });
      } else {
        isEdit.value = false;
        let url = process.env.VUE_APP_API_URL + "/api/vehicles";
        await axios
          .post(url, dataVehicle)
          .then((response) => {
            if (response.data.success) {
              let idVehicle = response.data.data[0];
              idAuto.value = response.data.data[0];
              saveImagesGalery(idVehicle);
            }
          })
          .catch((error) => {
            if (error.response) {
              let errors = Object.entries(error.response.data.errors);
              let mensaje = "";
              errors.forEach((value, index) => {
                mensaje += value[1];
              });
              Swal.fire({
                position: "center",
                icon: "error",
                title: mensaje,
                showConfirmButton: true,
              });
              sendDesperfectos.value = false;
              showLoader.value = false;
            }
          });
      }
      /* } catch (error) {
      console.log("ERROR VEHICLE");
      console.log(error);
      sendDesperfectos.value = false;
      showLoader.value = false;
    } */
    };

    const saveImagesGalery = async (idVehicle) => {
      let files = galery.value;
      let fineUpload = true;
      for (const item of files) {
        if (item.id == undefined) {
          let formData = new FormData();
          formData.append("file", item);
          formData.append("type", "Vehículo");
          let url =
            process.env.VUE_APP_API_URL + "/api/vehicle-images/" + idVehicle;
          const { data } = await axios.post(url, formData);
          if (!data.success) {
            fineUpload = false;
          }
        }
      }
      if (fineUpload) {
        saveImagesDefectos(idVehicle);
      }
    };

    const saveImagesDefectos = async (idVehicle) => {
      let files = desperfectosData.value.imagenesDes;
      let fineUpload = true;
      for (const item of files) {
        if (item.id == undefined) {
          let formData = new FormData();
          formData.append("file", item);
          formData.append("type", "Desperfecto");
          formData.append("description", desperfectosData.value.descripcion);
          let url =
            process.env.VUE_APP_API_URL + "/api/vehicle-images/" + idVehicle;
          const { data } = await axios.post(url, formData);
          if (!data.success) {
            fineUpload = false;
          }
        }
      }
      if (fineUpload) {
        showLoader.value = false;
        showModal(uploadVehicleRef.value);
        idToEdit.value = "";
      }
    };

    const cerrarModal = (goTo) => {
      hideModal(uploadVehicleRef.value);
      router.push(goTo);
    };

    onMounted(async () => {
      if (route.params.id) {
        let url =
          process.env.VUE_APP_API_URL + "/api/vehicles/" + route.params.id;
        idToEdit.value = route.params.id;
        try {
          const { data } = await axios.get(url);
          let editVehicle = data.data;
          dataEditVehicle.value = {
            vin: editVehicle.vin,
            brand: editVehicle.brand.id,
            brand_inteli: editVehicle.brand.reference,
            model: editVehicle.model.id,
            model_inteli: editVehicle.model.reference,
            version: editVehicle.version.id,
            version_inteli: editVehicle.version.reference,
            color: editVehicle.color.id,
            transmission: editVehicle.transmission.id,
            carroceria: editVehicle.type.id,
            fuel: editVehicle.fuel.id,
            mileage: editVehicle.mileage,
            has_certificate: editVehicle.has_certificate,
            price: editVehicle.price,
            days_in_floor: editVehicle.days_in_floor,
            agency: editVehicle.agency.id,
            unique_owner: editVehicle.unique_owner,
            agencies_services: editVehicle.agency_service,
            status: editVehicle.status.id,
            additional_description: editVehicle.description,
            year: editVehicle.year.year,
            year_inteli: editVehicle.year.reference,
          };
          dataDescription.value = {
            dueno: editVehicle.unique_owner,
            servicios: editVehicle.agency_service,
            descripcion: editVehicle.description,
          };
          editVehicle.images.forEach((value, index) => {
            if (value.type == "Vehiculo") {
              let imgObject = {
                id: value.id,
                path: value.path,
              };
              imgVehicle.value.push(imgObject);
            } else {
              let imgObject = {
                id: value.id,
                path: value.path,
                desc: value.desc,
              };
              imgDesperfecto.value.push(imgObject);
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    });

    return {
      regresar,
      siguiente,
      stepIValidation,
      stepIIValidation,
      stepIIIValidation,
      stepIVValidation,
      saveImagesGalery,
      step,
      sendForm,
      sendGalery,
      sendDescripcion,
      sendDesperfectos,
      formStepOne,
      galery,
      descripcion,
      desperfectosData,
      saveVehicle,
      saveImagesDefectos,
      uploadVehicleRef,
      dataEditVehicle,
      imgVehicle,
      imgDesperfecto,
      idToEdit,
      sendImagesEditVehicle,
      dataDescription,
      sendDataDescription,
      sendImgDesperfecto,
      idAuto,
      cerrarModal,
      showLoader,
      isEdit,
    };
  },
});
