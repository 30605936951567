
import { defineComponent, ref, toRefs, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";

export default defineComponent({
  name: "DesperfectosVehicle",
   props: {
      sendDesperfectos: {
        required: true,
        type: Boolean,
        default: false,
      },
      sendImgDesperfecto: {
        required: true,
        type: Array,
        default: [],
      },
    },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  emits: ["stepIVValidation"],
  setup(props, { emit }) {
    const desperfectosRef = ref();
    const imagesDes = ref<any>([]);
    const imagesThumDes = ref<any>([]);
    const indexImageDes = ref<number>(0);
    const inputFileDes = ref();
    const sendDesperfectos = toRefs(props).sendDesperfectos;
    const sendImgDesperfecto = toRefs(props).sendImgDesperfecto;
    const isEdit = ref<boolean>(false);

    let initialValues = ref({
        descDefectos: "",
    });

    const desperfectos = Yup.object().shape({
        descDefectos: Yup.string(),
    });

    const onInvalidSubmit = (values) => {
        console.log(values);
    };

    const save = async (values, { resetForm }) => {
        console.log(values);
      };
    
    const checkDataDes = (evt) => {
          evt.preventDefault();
      };

    const dropEventDes = (evt) => {
          const files = evt.dataTransfer.files;
          let errorsImages = false;
          for (const item of files) {
            if(item.type.includes('image')){
                if(imagesDes.value.length < 5){
                   let tamanio = item.size / 1024 / 1024;
                   if(tamanio > 1){
                       Swal.fire({
                          position: "center",
                          icon: "error",
                          title: "Solo se pueden subir imágenes inferiores a 1 MB",
                          showConfirmButton: true,
                        });
                        return false;
                   }else{
                        imagesDes.value.push(item);
                        createThumbnailsDes(item);
                   }
                }else{
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      title: "Ya no puedes cargar más imagenes, elimina alguna para poder cargar más",
                      showConfirmButton: true,
                    });
                    return false;
                }
            }else{
                errorsImages = true;
            }
          }
          if(errorsImages){
            Swal.fire({
                position: "center",
                icon: "error",
                title: "Algunos archivos no fueron cargados ya que no tienen el formato esperado",
                showConfirmButton: false,
                timer: 3000,
            });
          }
      };

    const createThumbnailsDes = (imgObject) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgObject);
        reader.addEventListener("load", function () {
            if(isEdit.value){
                imagesThumDes.value.push({
                    path:reader.result,
                });
            }else{
                imagesThumDes.value.push(reader.result);
            }
        });
      };

    const getFilesInputDes = () => {
          const files = inputFileDes.value.files;
          let errorsImages = false;
          for (const item of files) {
            if(item.type.includes('image')){
                if(imagesDes.value.length < 5){
                   let tamanio = item.size / 1024 / 1024;
                   if(tamanio > 1){
                       Swal.fire({
                          position: "center",
                          icon: "error",
                          title: "Solo se pueden subir imágenes inferiores a 1 MB",
                          showConfirmButton: true,
                        });
                        return false;
                   }else{
                        imagesDes.value.push(item);
                        createThumbnailsDes(item);
                   }
                }else{
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      title: "Ya no puedes cargar más imagenes, elimina alguna para poder cargar más",
                      showConfirmButton: true,
                    });
                    return false;
                }
            }else{
                errorsImages = true;
            }
          }
          if(errorsImages){
            Swal.fire({
                position: "center",
                icon: "error",
                title: "Algunos archivos no fueron cargados ya que no tienen el formato esperado",
                showConfirmButton: true,
            });
          }
      };

      const agregarImagenesDes = () => {
          inputFileDes.value.click();
      };

    const eliminarImagenDes = async(index) => {
        if(isEdit.value && imagesThumDes.value[index].id != undefined){
            let url = process.env.VUE_APP_API_URL+'/api/images/'+imagesThumDes.value[index].id;
            const { data } = await axios.delete(url);
            if(data.success){
                if(imagesThumDes.value.length > 0){
                    imagesThumDes.value.splice(index, 1);
                    imagesDes.value.splice(index, 1);
                }else{
                    imagesThumDes.value.splice(0);
                    imagesDes.value.splice(0);
                }
                indexImageDes.value = 0;
            }
        }else{
            imagesThumDes.value.splice(index, 1);
            imagesDes.value.splice(index, 1);
        }
      };

    const setImageDes = (index) => {
        indexImageDes.value = index;
      };
    
    const nextImageDes = () => {
          indexImageDes.value ++;
      };

      const prevImageDes = () => {
          if(indexImageDes.value > 0){
              indexImageDes.value --;
          }
      };

    watch(sendDesperfectos, async (current) => {
        if(current){
          const data = await desperfectosRef.value.validate();
          if(data.valid){
              emit("stepIVValidation", {
              success: true,
              data: {
                  descripcion: initialValues.value.descDefectos,
                  imagenesDes: imagesDes.value,
              },
            });
          }else{
            emit("stepIVValidation", {
              success: false,
              data: [],
            });
          }
        }
      });

    watch(sendImgDesperfecto, async (currentImgs:any) => {
        if(currentImgs.length > 0){
          isEdit.value = true;
          currentImgs.forEach((value, ind) => {
              if(value.desc != '' && ind == 0){
                initialValues.value.descDefectos = value.desc;
              }
              let objThum = {
                  id: value.id,
                  path: process.env.NODE_ENV === 'production' ? value.path : value.path.replace('localhost', 'localhost:8000'),
              }
              imagesThumDes.value.push(objThum);
              imagesDes.value.push(objThum);
          });
        }
      });

    return { 
        desperfectosRef,
        initialValues,
        onInvalidSubmit,
        save,
        imagesDes,
        imagesThumDes,
        indexImageDes,
        inputFileDes,
        dropEventDes,
        createThumbnailsDes,
        checkDataDes,
        getFilesInputDes,
        agregarImagenesDes,
        eliminarImagenDes,
        setImageDes,
        nextImageDes,
        prevImageDes,
        desperfectos,
        isEdit,
    }
  },
});
