
import { defineComponent, onMounted, ref, toRefs, reactive, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "VehicleForm",
  props: {
    sendForm: {
      required: true,
      type: Boolean,
      default: false,
    },
    dataEditVehicle: {
      required: true,
      type: Object,
      default: {},
    },
  },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  emits: ["stepIValidation"],
  setup(props, { emit }) {
    const newVehicleRef = ref();
    const certificado = ref<string>("Si");
    const tipoTransmision = ref<string>("Manual");
    const marcas = ref<any[]>([]);
    const modelos = ref<any[]>([]);
    const anios = ref<string[]>([]);
    const versiones = ref<any[]>([]);
    const combustibles = ref<string[]>([]);
    const carrocerias = ref<string[]>([]);
    const colores = ref<string[]>([]);
    const marcaSelected = ref<any>(0);
    const intelimodel = ref<any>("");
    const idmodel = ref<any>(0);
    const sendForm = toRefs(props).sendForm;
    const dataEditVehicle = toRefs(props).dataEditVehicle;

    let initialValues = ref({
      vin: "",
      id_brand: 0,
      brand: "Selecciona marca",
      brand_name: "",
      id_model: 0,
      model: "Selecciona modelo",
      model_name: "",
      id_year: 0,
      year: "Selecciona anio",
      year_name: "",
      id_version: 0,
      version: "Selecciona version",
      version_name: "",
      id_color: 0,
      color: "Selecciona color",
      transmision: 2,
      carroceria: "Selecciona carroceria",
      id_carroceria: 0,
      combustible: "Selecciona combustible",
      id_combustible: 0,
      kilometraje: "",
      certificado: certificado.value,
      precio: "",
      piso: 0,
    });

    const vehicle = Yup.object().shape({
      vin: Yup.string()
        .required("El vin es obligatorio")
        .min(17, "El vin debe de tener 17 caracteres")
        .max(17, "El vin debe de tener 17 caracteres")
        .label("VIN"),
      brand: Yup.string()
        .required("La marca es obligatoria")
        .notOneOf(["Selecciona marca"], "La marca es obligatoria")
        .label("Marca"),
      model: Yup.string()
        .required("El modelo es obligatorio")
        .notOneOf(["Selecciona modelo"], "El modelo es obligatoria")
        .label("Marca"),
      year: Yup.string()
        .required("El año es obligatorio")
        .notOneOf(["Selecciona anio"], "El año es obligatorio")
        .label("Año"),
      version: Yup.string()
        .required("La versión es obligatoria")
        .notOneOf(["Selecciona version"], "La versión es obligatoria")
        .label("Versión"),
      color: Yup.string()
        .required("El color es obligatorio")
        .notOneOf(["Selecciona color"], "El color es obligatorio")
        .label("Color"),
      carroceria: Yup.string()
        .required("La carrocería es obligatoria")
        .notOneOf(["Selecciona carroceria"], "La carrocería es obligatoria")
        .label("Carrocería"),
      combustible: Yup.string()
        .required("El tipo de combustible es obligatorio")
        .notOneOf(["Selecciona combustible"], "El tipo de combustible es obligatorio")
        .label("Combustible"),
      kilometraje: Yup.string()
        .typeError("El kilometraje debe de ser numérico")
        .required("El kilometraje es obligatorio")
        .label("Kilometraje"),
      precio: Yup.string()
        .typeError("El precio debe de ser numérico")
        .required("El precio es obligatorio")
        .label("Precio"),
      piso: Yup.number()
        .typeError("Los días en piso son obligatorios")
        .min(1, "Los días en piso deben de ser mayor o igual a uno")
        .required("Los días en piso son obligatorios")
        .label("Piso"),
    });

    /**
     * @method onInvalidSubmit
     * @param values from form
     * Se ejecuta en caso de que falten campos correctos en el formulario, lanza excepción
     */
    const onInvalidSubmit = (values) => {
      console.log("");
    };

    /**
     * @method save
     * @param values
     * @description Función que valida los campos que este correctos y
     * los regresa al padre para hacer el acumulado de valores y mandarlos
     * Junto con otros
     */
    const save = async (values, { resetForm }) => {
      console.log("");
    };

    /**
     * @method cambiarCertificado
     * @description Cambiar el estilo y el valor de los botones del
     * certificado, sirve para saber si el auto cuenta o no con los
     * certificados correspondientes
     * @param valor Puede ser Si o No
     */
    const cambiarCertificado = (valor) => {
      certificado.value = valor;
      initialValues.value.certificado = valor;
    };

    /**
     * @method cambiarTransmision
     * @param valor Cambia estilo y el valor de los botones de la
     * transmisión, para saber de que tipo de transmision es el
     * auto
     * @param valor recibe Manual o Automática
     */
    const cambiarTransmision = (valor) => {
      if (valor == "Manual") initialValues.value.transmision = 2;
      else {
        initialValues.value.transmision = 1;
      }
      tipoTransmision.value = valor;
    };

    const restarDias = () => {
      if (initialValues.value.piso > 0) {
        initialValues.value.piso--;
      }
    };

    const sumarDias = () => {
      initialValues.value.piso++;
    };

    const getBrands = async () => {
      let url = process.env.VUE_APP_API_URL + "/api/maxi/brands/v_1";
      try {
        const { data } = await axios.get(url);
        marcas.value = data.data;
        console.log("🚀 ~ file: VehicleForm.vue:545 ~ getBrands ~ marcas.value", marcas.value)
      } catch (error) {
        console.log(error);
      }
    };

    const getCombustibles = async () => {
      let url = process.env.VUE_APP_API_URL + "/api/fuels";
      try {
        const { data } = await axios.get(url);
        combustibles.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const getCarrocerias = async () => {
      let url = process.env.VUE_APP_API_URL + "/api/types";
      try {
        const { data } = await axios.get(url);
        carrocerias.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const getColores = async () => {
      let url = process.env.VUE_APP_API_URL + "/api/colors";
      try {
        const { data } = await axios.get(url);
        colores.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const onChangeBrand = async (evt) => {
      let marca = evt.target.value.split("-");
      console.log("🚀 ~ file: VehicleForm.vue:583 ~ onChangeBrand ~ marca", marca)
      marcaSelected.value = marca[0];
      initialValues.value.id_brand = marca[0];
      initialValues.value.model = "Selecciona modelo";
      modelos.value = [];
      initialValues.value.year = "Selecciona anio";
      anios.value = [];
      initialValues.value.version = "Selecciona version";
      versiones.value = [];
      let url = process.env.VUE_APP_API_URL + "/api/maxi/models/" + marca[0];
      try {
        const { data } = await axios.get(url);
        modelos.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const onChangeModel = async (evt) => {
      let datamodel = evt.target.value.split("-");
      console.log("🚀 ~ file: VehicleForm.vue:602 ~ onChangeModel ~ datamodel", datamodel)
      intelimodel.value = datamodel[0];
      idmodel.value = datamodel[0];
      initialValues.value.id_model = datamodel[0];
      initialValues.value.year = "Selecciona anio";
      anios.value = [];
      initialValues.value.version = "Selecciona version";
      versiones.value = [];
      let url =
        process.env.VUE_APP_API_URL +
        "/api/maxi/years/" +
        intelimodel.value
      try {
        const { data } = await axios.get(url);
        anios.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const onChangeAnio = async (evt) => {
      initialValues.value.version = "Selecciona version";
      versiones.value = [];
      let idAnio = evt.target.value;
      console.log("🚀 ~ file: VehicleForm.vue:627 ~ onChangeAnio ~ idAnio", idAnio)
      let anioText = evt.target.options[evt.target.options.selectedIndex].text;
      initialValues.value.id_year = parseInt(anioText);
      let url =
        process.env.VUE_APP_API_URL +
        "/api/maxi/versions/" + idAnio;
      try {
        const { data } = await axios.get(url);
       
          
            if (data.data.length > 0) {
              versiones.value = data.data;
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "No encontramos versiones, intenta nuevamente",
                showConfirmButton: true,
              });
            }
          
        
      } catch (error) {
        console.log(error);
      }
    };

    const onChangeVersion = async (evt) => {
      if (!isNaN(evt.target.value)) initialValues.value.id_version = evt.target.value;
      else initialValues.value.id_version = 0;
    };

    const onChangeColor = async (evt) => {
      if (!isNaN(evt.target.value)) initialValues.value.id_color = evt.target.value;
      else initialValues.value.id_color = 0;
    };

    const onChangeCarroceria = async (evt) => {
      if (!isNaN(evt.target.value)) initialValues.value.id_carroceria = evt.target.value;
      else initialValues.value.id_carroceria = 0;
    };

    const onChangeCombustible = async (evt) => {
      if (!isNaN(evt.target.value)) initialValues.value.id_combustible = evt.target.value;
      else initialValues.value.id_combustible = 0;
    };

    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    onMounted(async () => {
      await getBrands();
      await getCombustibles();
      await getCarrocerias();
      await getColores();
    });

    watch(sendForm, async (current) => {
      if (current) {
        const data = await newVehicleRef.value.validate();
        if (data.valid) {
          emit("stepIValidation", {
            success: true,
            data: { ...initialValues.value, 
              brand_name: 
                  marcas.value.filter((item) => item?.id == initialValues.value.id_brand)[0]?.name
                  ?? marcas.value.filter((item) => item?.id ==  initialValues.value.brand)[0]?.name, 
              model_name: 
                  modelos.value.filter((item) => item?.id == initialValues.value.id_model)[0]?.name
                  ?? modelos.value.filter((item) => item?.id == initialValues.value.model)[0]?.name,
              version_name: versiones.value.filter((item) => item?.id == initialValues.value.version)[0]?.name,
            },
          });
        } else {
          emit("stepIValidation", {
            success: false,
            data: [],
          });
        }
      }
    });

    watch(dataEditVehicle, async (current) => {
      if (current) {
        initialValues.value.vin = current.vin;
        initialValues.value.carroceria = current.carroceria == null ? 'Selecciona carroceria' : current.carroceria;
        initialValues.value.id_carroceria = current.carroceria == null ? '' : current.carroceria;
        initialValues.value.combustible = current.fuel == null ? 'Selecciona combustible' : current.fuel;
        initialValues.value.kilometraje = formatPrice(current.mileage);
        certificado.value = current.has_certificate;
        initialValues.value.precio = formatPrice(current.price);
        initialValues.value.piso = current.days_in_floor;
        initialValues.value.color = current.color == null ? 'Selecciona color' : current.color;
        initialValues.value.transmision = current.transmission == null ? 2 : current.transmission;
        if (current.transmission == 1) {
          tipoTransmision.value = "Automática";
        }
        if (current.transmission == 2) {
          tipoTransmision.value = "Manual";
        }
        if (current.transmission == null) {
          tipoTransmision.value = "Manual";
        }
        initialValues.value.brand = current.brand_inteli;
        console.log("🚀 ~ file: VehicleForm.vue:741 ~ watch ~ current.brand", current)
        console.log("🚀 ~ file: VehicleForm.vue:741 ~ watch ~ initialValues.value.brand", initialValues.value.brand)
        initialValues.value.id_brand = current.brand;
        marcaSelected.value = current.brand_inteli;
        let url = process.env.VUE_APP_API_URL + "/api/maxi/models/" + marcaSelected.value;
        try {
          const { data } = await axios.get(url);
          console.log("🚀 ~ file: VehicleForm.vue:749 ~ watch ~ data", data)
          modelos.value = data.data;
        } catch (error) {
          console.log(error);
        }
        initialValues.value.model = current.model_inteli == null ? 'Selecciona modelo' : current.model_inteli;
        console.log("🚀 ~ file: VehicleForm.vue:746 ~ watch ~ initialValues.value.model", initialValues.value.model)
        intelimodel.value = current.model_inteli;
        initialValues.value.id_model = current.model;
        idmodel.value = current.model;
        let urlanios =
          process.env.VUE_APP_API_URL +
          "/api/maxi/years/"  +
          current.model_inteli;
        try {
          const { data } = await axios.get(urlanios);
          anios.value = data.data;
        } catch (error) {
          console.log(error);
        }
        initialValues.value.year = current.year_inteli == null ? 'Selecciona anio' : current.year_inteli;
        console.log('🚀 ~ file: VehicleForm.vue:769 ~ watch ~ current.year_inteli;', current.year_inteli)

        initialValues.value.id_year = current.year == null ? 0 : parseInt(current.year);
        let urlversion =
          process.env.VUE_APP_API_URL +
          "/api/maxi/versions/" +
          current.year_inteli
        try {
          const { data } = await axios.get(urlversion);
          if (data) {
            versiones.value = data.data;
          }
        } catch (error) {
          console.log(error);
        }
        initialValues.value.id_version = current.version_inteli == null ? '' : current.version_inteli;
        initialValues.value.version = current.version == null ? 'Selecciona version' : current.version_inteli;
      }
    });

    const addMoney = () => {
      let precio = formatPrice(initialValues.value.precio);
      if (!isNaN(parseInt(precio))) {
        initialValues.value.precio = precio;
      } else {
        initialValues.value.precio = "";
      }
    };

    const addKm = () => {
      let kilometraje = formatPrice(initialValues.value.kilometraje);
      if (!isNaN(parseInt(kilometraje))) {
        initialValues.value.kilometraje = kilometraje;
      } else {
        initialValues.value.kilometraje = "";
      }
    };

    return {
      onInvalidSubmit,
      save,
      cambiarCertificado,
      cambiarTransmision,
      restarDias,
      sumarDias,
      getBrands,
      getColores,
      getCarrocerias,
      onChangeModel,
      onChangeBrand,
      onChangeAnio,
      getCombustibles,
      onChangeVersion,
      onChangeColor,
      onChangeCarroceria,
      onChangeCombustible,
      newVehicleRef,
      initialValues,
      certificado,
      tipoTransmision,
      marcas,
      modelos,
      marcaSelected,
      intelimodel,
      idmodel,
      anios,
      versiones,
      combustibles,
      carrocerias,
      colores,
      vehicle,
      formatPrice,
      addMoney,
      addKm,
    };
  },
});
