
import { defineComponent, ref, onMounted, toRefs, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";

export default defineComponent({
    name: "ImagesVehicle",
    components: {

    },
    props: {
        sendGalery: {
            required: true,
            type: Boolean,
            default: false,
        },
        imgVehicle: {
            required: true,
            type: Array,
            default: [],
        },
    },
    emits: ["stepIIValidation"],
    setup(props, { emit }) {
        const images = ref<any>([]);
        const imagesThum = ref<any>([]);
        const indexImage = ref<number>(0);
        const isEdit = ref<boolean>(false);
        const inputFile = ref();
        const sendGalery = toRefs(props).sendGalery;
        const imgVehicle = toRefs(props).imgVehicle;
        const backgroundSelected = ref<string>("1");

        const dropEvent = (evt) => {
            const files = evt.dataTransfer.files;
            let errorsImages = false;
            for (const item of files) {
                if (item.type.includes('image')) {
                    if (images.value.length < 20) {
                        let tamanio = item.size / 1024 / 1024;
                        if (tamanio > 1) {
                            Swal.fire({
                                position: "center",
                                icon: "error",
                                title: "Solo se pueden subir imágenes inferiores a 1 MB",
                                showConfirmButton: true,
                            });
                        } else {
                            images.value.push(item);
                            createThumbnails(item);
                        }
                    } else {
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: "Ya no puedes cargar más imagenes, elimina alguna para poder cargar más",
                            showConfirmButton: true,
                        });
                        return false;
                    }
                } else {
                    errorsImages = true;
                }
            }
            if (errorsImages) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Algunos archivos no fueron cargados ya que no tienen el formato esperado",
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        };

        const getFilesInput = async () => {
            const files = inputFile.value.files;
            let errorsImages = false;
            for (const item of files) {
                if (item.type.includes('image')) {
                    if (images.value.length < 20) {
                        let tamanio = item.size / 1024 / 1024;
                        if (tamanio > 1) {
                            Swal.fire({
                                position: "center",
                                icon: "error",
                                title: "Solo se pueden subir imágenes inferiores a 1 MB",
                                showConfirmButton: true,
                            });
                        } else {
                            try {
                                const imgMerged = await mergeImagesAI(item);
                                images.value.push(imgMerged);
                                createThumbnails(imgMerged);
                            } catch (error) {
                                console.error(error);
                            }
                            // mergeImagesAI(item);
                            // images.value.push(item);
                            // createThumbnails(item);
                        }
                    } else {
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: "Ya no puedes cargar más imagenes, elimina alguna para poder cargar más",
                            showConfirmButton: true,
                        });
                        return false;
                    }
                } else {
                    errorsImages = true;
                }
            }
            if (errorsImages) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Algunos archivos no fueron cargados ya que no tienen el formato esperado",
                    showConfirmButton: true,
                });
            }
        };

        const checkData = (evt) => {
            evt.preventDefault();
        };

        const createThumbnails = (imgObject) => {
            const reader = new FileReader();
            reader.readAsDataURL(imgObject);
            reader.addEventListener("load", function () {
                if (isEdit.value) {
                    imagesThum.value.push({
                        path: reader.result,
                    });
                } else {
                    imagesThum.value.push(reader.result);
                }
            });
        };

        const mergeImagesAI = (userFile) => {
            return new Promise((resolve, reject) => {
                const userImage = new Image();
                const backgroundImage = new Image();

                const userFileReader = new FileReader();
                // Configurar el lector de archivos para la imagen del usuario
                userFileReader.onload = () => {
                    if (typeof userFileReader.result === 'string') {
                        userImage.src = userFileReader.result;
                    }
                };
                // Configurar las fuentes
                // userImage.src = userImageSrc;
                backgroundImage.src = `media/bg-cars/wp-${backgroundSelected.value}.jpeg`; // Ruta a tu fondo

                // Esperar a que ambas imágenes se carguen
                backgroundImage.onload = () => {
                    userImage.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        if (!ctx) {
                            reject('No se pudo obtener el contexto 2D del canvas');
                            return;
                        }
                        canvas.width = backgroundImage.width;
                        canvas.height = backgroundImage.height;

                        // Dibuja el fondo primero
                        ctx.drawImage(backgroundImage, 0, 0);
                        // Dibuja la imagen del usuario encima (ajusta la posición y tamaño según sea necesario)
                        // Calcula las coordenadas para centrar la imagen del usuario
                        const x = (backgroundImage.width - userImage.width) / 2;
                        const y = (backgroundImage.height - userImage.height) / 2;

                        // Dibuja la imagen del usuario centrada
                        ctx.drawImage(userImage, x, y);

                        // Exportar la imagen combinada
                        const mergedImageUrl = canvas.toDataURL('image/png');

                        const file = dataURLToFile(mergedImageUrl, `merged-image${Math.floor(Math.random() * 10000)}.png`);

                        resolve(file);
                    };

                    userImage.onerror = () => {
                        reject('Error al cargar la imagen del usuario');
                    };
                };

                backgroundImage.onerror = () => {
                    reject('Error al cargar la imagen de fondo');
                };

                userFileReader.readAsDataURL(userFile);
            });
        };

        function dataURLToFile(dataUrl, fileName) {
            const [header, base64Data] = dataUrl.split(',');
            const mimeType = header.match(/:(.*?);/)[1];
            const byteString = atob(base64Data);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const intArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new File([intArray], fileName, { type: mimeType });
        }


        const nextImage = () => {
            indexImage.value++;
        };

        const prevImage = () => {
            if (indexImage.value > 0) {
                indexImage.value--;
            }
        };

        const eliminarImagen = async (index) => {
            if (isEdit.value && imagesThum.value[index].id != undefined) {
                let url = process.env.VUE_APP_API_URL + '/api/images/' + imagesThum.value[index].id;
                const { data } = await axios.delete(url);
                if (data.success) {
                    if (imagesThum.value.length > 0) {
                        imagesThum.value.splice(index, 1);
                        images.value.splice(index, 1);
                    } else {
                        imagesThum.value.splice(0);
                        images.value.splice(0);
                    }
                    indexImage.value = 0;
                }
            } else {
                imagesThum.value.splice(index, 1);
                images.value.splice(index, 1);
            }
        };

        const setImage = (index) => {
            indexImage.value = index;
        };

        const agregarImagenes = () => {
            inputFile.value.click();
        };

        const changeBackground = (valor) => {
            backgroundSelected.value = valor;
        };

        watch(sendGalery, async (currentImgs) => {
            if (currentImgs) {
                if (images.value.length > 0) {
                    emit("stepIIValidation", {
                        success: true,
                        data: images.value,
                    });
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Debes por lo menos cargar una imagen",
                        showConfirmButton: true,
                    });
                    emit("stepIIValidation", {
                        success: false,
                        data: [],
                    });
                }
            }
        });

        watch(imgVehicle, async (currentImgs: any) => {
            if (currentImgs.length > 0) {
                isEdit.value = true;
                currentImgs.forEach((value, ind) => {
                    let objThum = {
                        id: value.id,
                        path: process.env.NODE_ENV === 'production' ? value.path : value.path.replace('localhost', 'localhost:8000'),
                    }
                    imagesThum.value.push(objThum);
                    images.value.push(objThum);
                });
            }
        });

        return {
            dropEvent,
            checkData,
            setImage,
            agregarImagenes,
            eliminarImagen,
            getFilesInput,
            nextImage,
            prevImage,
            createThumbnails,
            changeBackground,
            images,
            imagesThum,
            indexImage,
            inputFile,
            isEdit,
            backgroundSelected,
        };
    },
});
