
import { defineComponent, ref, toRefs, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  name: "DescripcionVehicle",
  props: {
    sendDescripcion: {
      required: true,
      type: Boolean,
      default: false,
    },
    sendDataDescription: {
      required: true,
      type: Object,
      default: {},
    },
  },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  emits: ["stepIIIValidation"],
  setup(props, { emit }) {
    const descripcionRef = ref();
    const dueno = ref<string>("Si");
    const servicios = ref<string>("Si");
    const sendDescripcion = toRefs(props).sendDescripcion;
    const sendDataDescription = toRefs(props).sendDataDescription;

    let initialValues = ref({
      dueno: dueno.value,
      servicios: servicios.value,
      descripcion: "",
    });

    const onInvalidSubmit = (values) => {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Faltan datos de registrar en la sección de galería",
        showConfirmButton: true,
      });
    };

    const save = async (values, { resetForm }) => {
    };

    const cambiarDueno = (valor) => {
      dueno.value = valor;
      initialValues.value.dueno = valor;
    };

    const cambiarServicios = (valor) => {
      servicios.value = valor;
      initialValues.value.servicios = valor;
    };

    const descripcionSch = Yup.object().shape({
      descripcion: Yup.string()
        .required("La descripción es obligatoria")
        .label("Descripción"),
    });

    watch(sendDescripcion, async (currentDesc) => {
      if (currentDesc) {
        const data = await descripcionRef.value.validate();
        if (data.valid) {
          emit("stepIIIValidation", {
            success: true,
            data: initialValues.value,
          });
        } else {
          emit("stepIIIValidation", {
            success: false,
            data: [],
          });
        }
      }
    });

    watch(sendDataDescription, async (current) => {
      if (current) {
        dueno.value = current.dueno;
        servicios.value = current.servicios;
        initialValues.value.descripcion = current.descripcion == null ? "" : current.descripcion;
      }
    });

    return {
      onInvalidSubmit,
      cambiarDueno,
      cambiarServicios,
      descripcionRef,
      initialValues,
      save,
      dueno,
      servicios,
      descripcionSch,
    };
  },
});
